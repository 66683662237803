<template>
  <div>
    <header class="page-header">
      <PageTitle v-if="menuItem" :item="menuItem" property="name"/>
    </header>

    <div class="main-content">
      <div class="pane table-pane">
        <formulate-form v-if="menuItem && !isLoading" v-model="menuItem" @submit="submit">
          <formulate-input type="select" :options="{'category': $t('Category'), 'link': $t('Link'), 'file': $t('Bestand')}" name="type" label="Type"></formulate-input>

          <formulate-input v-if="!iconFileData" :uploader="uploadIcon" :upload-url="uploadUrl" error-behavior="submit" name="files" type="file" upload-behavior="live" label="Icon"></formulate-input>
          <div v-if="iconFileData" class="image-placeholder">
            <img width="50" :src="iconFileData" alt="Alt img"/>
            <button type="button" @click="removeFileData()" class="btn btn-remove-file">{{ $t('Bestand verwijderen') }}</button>
          </div>

          <formulate-input :label="$t('Bovenliggend menu item')" name="parent_id" :options="menuItems" type="select"></formulate-input>
          <formulate-input :label="$t('GA event')" name="ga_event" type="text"></formulate-input>

          <formulate-input name="roles" :label="$t('Zichtbaar voor')" :options="roles" type="checkbox"></formulate-input>
          <formulate-input name="dealer_required" :label="$t('Enkel zichtbaar indien dealer geslecteerd')" type="checkbox"></formulate-input>

          <formulate-input groupRepeatable-class="translations-group formulate-input-group-repeatable" type="group" name="translations" #default="{ index }">
            <h3>Vertaling: {{ activeLocalesBack[getLangCode(menuItem, index)] }}</h3>
            <button v-if="getLangCode(menuItem, index) !== 'nl'" type="button" class="btn btn-remove-translation" @click="removeTrans(menuItem, index)">Remove</button>

            <formulate-input type="hidden" name="lang_code"></formulate-input>
            <formulate-input type="text" name="name" label="Naam"></formulate-input>

            <formulate-input validation="required" v-if="menuItem.type === 'link'" type="text" name="url" label="URL"></formulate-input>

            <formulate-input :disabled="isUploading" validation="required" @change="activeIndex = index" v-if="menuItem.type === 'file' && !fileData[index]" :uploader="uploadFile" :upload-url="uploadUrl" error-behavior="submit" name="files" type="file" upload-behavior="live" label="Bestand"></formulate-input>
            <div v-if="menuItem.type === 'file' && fileData && fileData[index]" class="image-placeholder">
              <img width="50" :src="fileData[index]" alt="Alt img"/>
              <button type="button" @click="removeFileData(index)" class="btn btn-remove-file">Bestand verwijderen</button>
            </div>
          </formulate-input>

          <div v-if="Object.keys(activeLocalesBack).length > menuItem.translations.length">
            <h4>{{ $t('Vertaling toevoegen') }}</h4>

            <div class="language-buttons">
              <button v-if="!hasTrans(menuItem, 'fr')" @click="addTrans(menuItem, 'fr')" type="button" class="btn">{{ activeLocalesBack['fr'] }}</button>
              <button v-if="!hasTrans(menuItem, 'en')" @click="addTrans(menuItem, 'en')" type="button" class="btn">{{ activeLocalesBack['en'] }}</button>
              <button v-if="!hasTrans(menuItem, 'sv')" @click="addTrans(menuItem, 'sv')" type="button" class="btn">{{ activeLocalesBack['sv'] }}</button>
            </div>
          </div>

          <formulate-input :disabled="isUploading" input-class="btn" type="submit" :label="isLoading ? $t('Even geduld...') : $t('Opslaan')"/>
        </formulate-form>
      </div>
    </div>
  </div>
</template>

<script>
import { ApiService } from '@/services/admin/api.service'
import { notification } from '@/notifications'
import PageTitle from '@/components/admin/PageTitle'

export default {
  components: { PageTitle },
  data () {
    return {
      isLoading: true,
      isUploading: false,
      activeIndex: null,
      contextMenuOptions: [{ id: 'deleteMenuItem', name: 'Menu item verwijderen' }],
      menuItemId: parseInt(this.$route.params.id),
      menuItem: null,
      iconFileData: null,
      fileData: [],
      menuItems: [],
      activeUploader: null,
      uploader: ApiService.getAxiosInstance(),
      uploadUrl: '/files',
      roles: {}
    }
  },
  async mounted () {
    this.menuItem = {
      translations: [{ lang_code: this.activeLocale }],
      weight: 0
    }

    if (this.menuItemId) {
      await this.fetchMenuItem(this.menuItemId)
    }

    if (this.menuItem.icon_file_id) {
      this.iconFileData = (await ApiService.getFile(this.menuItem.icon_file_id)).data
    }

    await this.getFiles()
    await this.getMenuItems()
    await this.getRoles()

    this.isLoading = false
  },
  methods: {
    async getMenuItems () {
      const data = (await ApiService.fetchMenuItems()).data
      this.menuItems = this.formatList(data)
    },
    async getRoles () {
      this.roles = {}
      await ApiService.fetchRoles(0, 25).then(res => {
        res.data.roles.forEach(role => {
          this.roles[role.id] = role.name
        })
      })
    },
    formatList (items, depth = 0) {
      let formatItems = []

      if (depth === 0) {
        formatItems.push({
          label: 'Geen',
          value: null
        })
      }

      items.forEach((item) => {
        if (item.type === 'category' && item.id !== this.menuItemId) {
          let prefix = ''
          for (let i = 0; i < depth; i++) {
            prefix += '-'
          }

          formatItems.push({
            label: prefix + ' ' + this.getTranslation(item).name,
            value: item.id
          })

          if (item.children.length > 0) {
            formatItems = formatItems.concat(this.formatList(item.children, depth + 1))
          }
        }
      })

      return formatItems
    },
    async uploadFile (file, progress, error, option) {
      this.isUploading = true

      const formData = new FormData()
      formData.append('files', file)
      const res = await ApiService.uploadFiles(formData)

      if (res.status === 200) {
        this.isUploading = false
        const fileId = res.data[0]
        this.fileData[this.activeIndex] = (await ApiService.getFile(fileId)).data
        this.menuItem.translations[this.activeIndex].file_id = fileId
        this.menuItem.translations[this.activeIndex].files = null
      }
    },
    async uploadIcon (file, progress, error, option) {
      this.isUploading = true

      const formData = new FormData()
      formData.append('files', file)
      const res = await ApiService.uploadFiles(formData)

      if (res.status === 200) {
        this.isUploading = false
        const fileId = res.data[0]
        this.iconFileData = (await ApiService.getFile(fileId)).data
        this.menuItem.icon_file_id = fileId
        this.menuItem.files = null
      }
    },
    async removeFileData (index) {
      if (index !== undefined) {
        await ApiService.deleteFile(this.menuItem.translations[index].file_id)
      } else {
        await ApiService.deleteFile(this.menuItem.icon_file_id)
      }

      if (index !== undefined) {
        this.menuItem.translations[index].file_id = null
        this.fileData[index] = null
      } else {
        this.menuItem.icon_file_id = null
        this.iconFileData = null
      }

      await this.getFiles()
    },
    async getFiles () {
      for (let i = 0; i < this.menuItem.translations.length; i++) {
        const translation = this.menuItem.translations[i]
        this.fileData[i] = translation.file_id ? (await ApiService.getFile(translation.file_id)).data : null
      }
    },
    fetchMenuItem: async function (id) {
      this.menuItem = (await ApiService.fetchMenuItem(id)).data
      this.title = this.getTranslation(this.menuItem).name
    },
    async submit (menuItem) {
      this.isLoading = true

      for (let i = 0; i < menuItem.translations.length; i++) {
        const translation = menuItem.translations[i]
        if (translation.files?.results?.[0]?.[0] !== undefined) {
          translation.file_id = translation.files.results[0][0]
          translation.files = null
        }
      }

      const res = await ApiService.saveMenuItem(menuItem)
      if (res.status === 200) {
        notification(this.$t('Menu item succesvol aangepast!'))
        await this.$router.push({ name: 'admin.menuitems' })
        this.isLoading = false
      }
    }
  },
  computed: {}
}
</script>
